import React, { useEffect, useState } from 'react';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Layout, message, theme, Form, Modal, Drawer, Row, Col, Input, Button, Select, Table, Space, Tooltip } from 'antd';
import { formatDateTime, isNotNullUndefined, isNotNullUndefinedBlank } from "../helpers/common";
import { useDeletePayment, useGetPayments, useAddPayment, useGetPaymentsByApplicationID, useGetPaymentsByCustomerID } from '../services/customerpayment.service';
import { useGetApplicationsByCustomerID, useGetApplications } from '../services/customerapplication.service';
import IResponse from "../types/response";
import { ICustomerPayment } from "../types/customerpayment";
import { PlusCircleFilled, LeftCircleFilled, CheckOutlined } from '@ant-design/icons';
import { ICustomer } from '../types/customer';
import { useGetCustomer } from '../services/customer.service';
import { useParams, useNavigate } from "react-router-dom";
import { storage } from '../utils/storage';

const { Column } = Table;

const CustomerApplicationPayments = () => {
    const { id } = useParams<{ id: string }>();
    const [applicationId, setApplicationId] = useState<string | undefined>(id);
    const [customerId, setCustomerId] = useState("");
    const { token: { colorBgContainer, borderRadiusLG }, } = theme.useToken();
    const [customerApplicationPayments, setCustomerApplicationPayments] = useState<ICustomerPayment[]>([]);
    const [open, setOpen] = useState(false);
    const [paymentId, setPaymentId] = useState();
    const [applicationName, setApplicationName] = useState();
    const [form] = Form.useForm();
    const [applicationList, setApplicationList] = useState<any[]>([]);
    const [allApplicationList, setAllApplicationList] = useState<any[]>([]);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [modalType, setModalType] = useState<'delete' | 'update' | 'add' | ''>('');
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const navigate = useNavigate();
    const [header, setHeader] = useState<string | undefined>("");

    useEffect(() => {
        setApplicationId(id);
    }, [id]);

    useEffect(() => {
        if (storage.getUserRoleId() == 2) {
            var userId = storage.getUserId() ?? "";
            setCustomerId(userId);
        }

        fetchPayments();
        fetchCustomerMutation.mutate({ customerId: "" });
        getApplications.mutate({});
    }, [applicationId, customerId]);

    useEffect(() => {
        if (isNotNullUndefinedBlank(customerId) && isNotNullUndefinedBlank(applicationId)) {
            var customer = customers.find((x: any) => x.customerId === customerId);
            var application = allApplicationList.find((x: any) => x.applicationId === applicationId);
            if (isNotNullUndefined(customer) && isNotNullUndefined(application)) {
                setHeader((customer?.firstName ?? "") + " " + (customer?.lastName ?? "") + " - #" + application.applicationCode + " - " + application.applicationName)
            } else {
                setHeader("All Payments")
            }
        }
        else if (customerId !== undefined && customerId !== null && customerId !== "") {
            var customer = customers.find((x: any) => x.customerId === customerId);
            if (isNotNullUndefined(customer)) {
                setHeader((customer?.firstName ?? "") + " " + (customer?.lastName ?? ""))
            } else {
                setHeader("All Payments")
            }
        }
        else if (applicationId !== undefined && applicationId !== null && applicationId !== "") {
            var application = allApplicationList.find((x: any) => x.applicationId === applicationId);
            if (isNotNullUndefined(application)) {
                setHeader("#" + application.applicationCode + " - " + application.applicationName)
            } else {
                setHeader("All Payments")
            }
        }
        else {
            setHeader("All Payments")
        }
    }, [customerApplicationPayments, customers, allApplicationList]);

    // useEffect(() => {
    //     fetchCustomerMutation.mutate({ customerId: "" });
    //     fetchApplications(customerId);
    //     form.setFieldsValue({
    //         customerId: customerId
    //     });
    // }, [customerId, applicationId]);

    const fetchPayments = () => {
        if (isNotNullUndefinedBlank(customerId) && isNotNullUndefinedBlank(applicationId)) {
            getApplicationPaymentsByApplicationID.mutate({ applicationId: applicationId });
        } else if (customerId !== undefined && customerId !== null && customerId !== "") {
            getApplicationPaymentsByCustomerID.mutate({ customerId: customerId });
        }
        else if (applicationId !== undefined && applicationId !== null && applicationId !== "") {
            getApplicationPaymentsByApplicationID.mutate({ applicationId: applicationId });
        }
        else {
            getApplicationPayments.mutate({});
        }
    };

    const getApplicationPayments = useGetPayments({
        onSuccess: (response: IResponse) => {
            if (response != null && response.data) {
                setCustomerApplicationPayments(response.data);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const getApplicationPaymentsByApplicationID = useGetPaymentsByApplicationID({
        onSuccess: (response: IResponse) => {
            if (response != null && response.data) {
                setCustomerApplicationPayments(response.data);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const getApplicationPaymentsByCustomerID = useGetPaymentsByCustomerID({
        onSuccess: (response: IResponse) => {
            if (response != null && response.data) {
                setCustomerApplicationPayments(response.data);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const data = customerApplicationPayments.map((item) => ({
        key: item.paymentId,
        applicationId: item.applicationId,
        applicationName: item.applicationName,
        customerId: item.customerId,
        customerName: item.customerName,
        amount: `$${item.amount}`,
        createdAt: item.createdAt ? formatDateTime(item.createdAt, "MM-DD-YYYY HH:mm AMPM") : "",
    }));

    const fetchCustomerMutation = useGetCustomer({
        onSuccess: (response: IResponse) => {
            if (response != null && response !== undefined) {
                setCustomers(response.data);
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const getApplications = useGetApplications({
        onSuccess: (response: IResponse) => {
            if (response != null && response.data) {
                setAllApplicationList(response.data);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const fetchApplications = async (customerId: string = "") => {
        form.setFieldValue("applicationId", "");
        fetchAllApplicationMutation.mutate({ customerId: customerId });
    };

    const fetchAllApplicationMutation = useGetApplicationsByCustomerID({
        onSuccess: (response: any) => {
            if (isNotNullUndefined(response.data)) {
                var pendingPayments = response.data.filter((x: { paymentStatus: string; }) => x.paymentStatus === "Pending");
                setApplicationList(pendingPayments);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const onDeletePayment = (record: any) => {
        setModalType('delete');
        setIsConfirmModalVisible(true);
        setPaymentId(record.key.toString());
        setApplicationName(record.applicationName);
        // deleteApplicationPayment.mutate(record.key.toString());
    }

    const deleteApplicationPayment = useDeletePayment({
        onSuccess: (response: IResponse) => {
            if (response != null && response.statusCode == 200) {
                fetchPayments();
                setIsConfirmModalVisible(false);
                message.success(response.statusMessage);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        },
    });

    const addPayment = useAddPayment({
        onSuccess: (response: IResponse) => {
            if (response !== null && response.statusCode === 200) {
                fetchPayments();
                message.success(response.statusMessage);
                form.resetFields();
                setIsConfirmModalVisible(false);
                setOpen(false);
            }
            else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        },
    });

    const onFinish = () => {
        setModalType('add');
        const applicationId = form.getFieldValue("applicationId");
        const amount = form.getFieldValue("amount");

        if (applicationId && amount) {
            setIsConfirmModalVisible(true);
            //addPayment.mutate({ applicationId: form.getFieldValue("applicationId"), amount: form.getFieldValue("amount") });
        } else {
            message.error("Please fill in all mandatory fields before adding a place.")
        }
    };

    const showDrawer = () => {
        form.resetFields();
        setOpen(true);
        setApplicationList([]);

        if (isNotNullUndefinedBlank(applicationId)) {
            const customerId = localStorage.getItem('applicationPaymentCustomerID')?.toLowerCase();
            fetchApplications(customerId);
            form.setFieldsValue({
                customerId: localStorage.getItem('applicationPaymentCustomerID')?.toLowerCase(),
                applicationId: applicationId
            });
        }
        else if (isNotNullUndefinedBlank(customerId)) {
            fetchApplications(customerId);
            form.setFieldsValue({
                customerId: customerId
            });
        }
    };

    const onClose = () => {
        setOpen(false);
    };

    const getModelContent = () => {
        let modalContent = <></>;
        if (modalType === 'delete') {
            modalContent = (
                <p>Are you sure you want to delete the payment for <b>{applicationName}</b> application? Once deleted, the payment will not be recovered. Do you wish to proceed?</p>
            );
        }
        else if (modalType === 'add') {
            modalContent = (
                <p>Are you sure you want to add payment for this <b>{applicationName}</b> application? Once added, the payment can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        return modalContent;
    }

    const handleSave = async () => {
        if (modalType === 'delete') { deleteApplicationPayment.mutate(paymentId); }
        else if (modalType === 'add') {
            addPayment.mutate({ applicationId: form.getFieldValue("applicationId"), amount: form.getFieldValue("amount") });
        }
    };

    const getModalTitle = () => {
        let message = "";
        if (modalType === 'delete') {
            message = "Confirm Deletion!";
        }
        else if (modalType === 'add') {
            message = "Confirm Add!";
        }
        return message;
    }

    const handleCancel = () => {
        setIsConfirmModalVisible(false);
    };

    const onBackToCustomerApplications = (record: any) => {
        localStorage.setItem('selectedKey', "3");
        const applicationpagePath = localStorage.getItem('customerApplicationPagePath') ?? "/applications";
        navigate(applicationpagePath);
    };

    return (
        <Layout>
            <div className='container' style={{ background: colorBgContainer, borderRadius: borderRadiusLG, }}>
                <div className='add-customer-btn'>
                    <div className='customer-name'>
                        <h2>{header}</h2>
                    </div>
                    <div className='create-back-btn'>
                        {(isNotNullUndefinedBlank(applicationId)) && (<Button className='second-btn' onClick={onBackToCustomerApplications}> <LeftCircleFilled />Back to Customer Application</Button>)}
                        {((isNotNullUndefinedBlank(applicationId) && data.length <= 0) || !isNotNullUndefinedBlank(applicationId)) && (<Button type="primary" onClick={showDrawer}><PlusCircleFilled /> Add Application Payment</Button>)}
                    </div>
                </div>


                <Drawer title="Create Application Payment" onClose={onClose} open={open} width={400}>
                    <Form layout="vertical" form={form} onFinish={onFinish} className='add-payment-drawer'>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="customerId"
                                    label="Customer"
                                    rules={[{ required: true, message: 'Please select a customer.' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Please select customer"
                                        optionFilterProp="label"
                                        options={customers ? customers.map(x => ({ label: x.firstName + ' ' + x.lastName + ' (' + x.email + ')', value: x.customerId })) : []}
                                        onChange={fetchApplications}
                                        allowClear
                                        disabled={isNotNullUndefinedBlank(applicationId) || isNotNullUndefinedBlank(customerId)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="applicationId"
                                    label="Application"
                                    rules={[{ required: true, message: "Please select any application." }]}
                                    validateTrigger="onBlur"
                                >
                                    <Select
                                        showSearch
                                        className=""
                                        placeholder="Please select Application"
                                        optionFilterProp="label"
                                        options={applicationList ? applicationList.map(x => ({ label: x.applicationName, value: x.applicationId })) : []}
                                        allowClear
                                        disabled={isNotNullUndefinedBlank(applicationId)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="amount"
                                    label="Amount"
                                    rules={[{ required: true, message: 'Please enter Amount' }]}
                                >
                                    <Input placeholder="Please enter amount" type='number' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Space className='add-payment-btn'>
                            <Button onClick={onClose} size="large"><CloseOutlined />Cancel</Button>
                            <Button htmlType="submit" type="primary" onClick={onFinish} size="large"><CheckOutlined />Create</Button>
                        </Space>
                    </Form>
                </Drawer>

                <Table dataSource={data}>
                    <Column title="Customer Name" dataIndex="customerName" key="customerName" />
                    <Column title="Application Name" dataIndex="applicationName" key="applicationName" />
                    <Column title="Amount" dataIndex="amount" key="amount" />
                    <Column title="Created At (UTC)" dataIndex="createdAt" key="createdAt" />
                    <Column
                        title="Action"
                        className='table-icon'
                        key="action"
                        render={(_: any, record: ICustomerPayment) => (
                            <Space size="middle">
                                <Tooltip title="Delete Payment">
                                    <Button icon={<DeleteOutlined />} onClick={() => onDeletePayment(record)}></Button>
                                </Tooltip>
                            </Space>
                        )}
                    />
                </Table>
            </div>

            <Modal
                title={<span className="custom-modal-title">{getModalTitle()}</span>}
                open={isConfirmModalVisible}
                onOk={handleSave}
                onCancel={handleCancel}
                closeIcon={<CloseOutlined className="text-gray-500 hover:text-black" />}
                footer={[
                    <Space className='add-payment-btn'>
                        <Button key="back" onClick={handleCancel} className="hover:!text-black hover:!border-black">
                            Cancel
                        </Button>
                        <Button type='primary' key="submit" onClick={handleSave} className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                            Confirm
                        </Button>
                    </Space>
                ]}
            >
                {getModelContent()}
            </Modal>
        </Layout>
    )
}

export default CustomerApplicationPayments