import React, { ReactNode, useState, useEffect } from 'react';
import { AppstoreOutlined, TeamOutlined, DownOutlined, UserOutlined, SettingOutlined, DollarOutlined } from '@ant-design/icons';
import { Avatar, Breadcrumb, Dropdown, Layout, Menu, MenuProps, Space, theme } from 'antd';
import { useNavigate } from "react-router-dom";
import { storage } from '../utils/storage';
import logo from '../images/seiler_Tucker_logo.png';
import icon from '../images/seiler_Tucker_icon.png';

const { Header, Content, Footer, Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
    return { key, icon, children, label, } as MenuItem;
}

const items: MenuItem[] = [
    // getItem('Dashboard', '1', <AppstoreOutlined />),
    getItem('Customers', '2', <TeamOutlined />),
    getItem('Applications', '3', <SettingOutlined />),
    getItem('Payments', '4', <DollarOutlined />)
];

type MainLayoutProps = {
    children: ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {
    const [selectedKey, setSelectedKey] = useState(localStorage.getItem('selectedKey') || '1');
    const navigate = useNavigate();

    const redirectToMenuPage = (item: any) => {
        if (parseInt(item.key) === 2) {
            navigate('/customers');
            localStorage.setItem('selectedKey', "2");
        }
        else if (parseInt(item.key) === 3) {
            navigate('/applications');
            localStorage.setItem('selectedKey', "3");
        }
        else if (parseInt(item.key) === 4) {
            navigate('/application/payments');
            localStorage.setItem('selectedKey', "4");
        }
        setSelectedKey(item.key);
    }

    const profileMenu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: 'Logout',
                },
            ]}
            onClick={({ key }) => {
                if (key === '1') {
                    window.localStorage.clear();
                    navigate('/');
                }
            }}
        />
    );

    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [filteredItems, setFilteredItems] = useState<MenuItem[]>([]);
    const [userName, setUserName] = useState<string>("");

    useEffect(() => {
        if (storage.getUserRoleId() == 2) {
            var items1 = items.filter(item => item?.key !== '2');
            setFilteredItems(items1)
        }
        else {
            setFilteredItems(items);
        }

        setUserName(storage.getUserRoleId() == 1 ? "Admin" : storage.getUserName() ?? "");
        //setSelectedKey(item.key);
    }, [selectedKey]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider width={306} collapsedWidth={102} style={{ background: colorBgContainer }} className='aside-bar' collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="demo-logo-vertical">
                    {!collapsed &&
                        <img width={240}
                            src={logo} title='Logo'
                        />
                    }
                    {
                        collapsed &&
                        <img height={47}
                            src={icon} title='Icon'
                        />
                    }
                </div>
                <Menu selectedKeys={[selectedKey]} onClick={(e) => { redirectToMenuPage(e) }} theme="light" defaultSelectedKeys={['2']} mode="inline" items={filteredItems} />
            </Sider>
            <Layout>
                <Header style={{ padding: 0, background: colorBgContainer }}>
                    <div className='login-btn'>
                        <div>
                        </div>
                        <div className='user-profile'>
                            <div className="user-details">
                                <span className='username'>{userName}</span>
                                <span className='email'>{storage.getUserEmail() ?? ""}</span>
                            </div>

                            <Dropdown overlay={profileMenu} trigger={['click']}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <Avatar icon={<UserOutlined />} />
                                        {/* <DownOutlined /> */}
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                </Header>
                <Content style={{ margin: '0 16px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item></Breadcrumb.Item>
                    </Breadcrumb>
                    <div className='container seiler-table'
                        style={{ padding: 24, background: colorBgContainer, borderRadius: borderRadiusLG, }}>

                        {children}

                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    © Copyright 2016 - 2024 | Michelle Seiler Tucker | Seiler Tucker Inc. | Exit Rich | All Rights Reserved.
                </Footer>
            </Layout>
        </Layout>
    );
};
export default MainLayout;