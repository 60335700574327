import { get, patch, post } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useGetApplications = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("customer/applications");
    }, options);
};

export const useGetApplicationsByCustomerID = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("customer/" + data.customerId + "/applications");
    }, options);
};

export const useCreateCustomerApplication = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("customer/" + data.customerId + "/application", data);
    }, options);
};

export const useUpdateCustomerApplication = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("customer/" + data.customerId + "/application/" + data.applicationId, data);
    }, options);
};

export const useDeleteCustomerApplication = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (applicationId: any) => {
        return await patch("customer/application/" + applicationId + "/delete", {});
    }, options);
};

export const useGetApplicationDocuments = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (applicationId) => {
        return await get("customer/application/" + applicationId + "/documents");
    }, options);
};

export const useGetDocuments = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("documents");
    }, options);
};

export const useUploadCustomerApplicationDocuments = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any[]) => {
        return await post("customer/application/upload/documents", data);
    }, options);
};