import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './components/login';
import Customer from './components/customers';
import CustomerApplications from './components/customerapplications';
import CustomerPayments from './components/customerpayment';
import CustomerApplicationDocuments from './components/customerapplicationdocuments';
import MainLayout from './components/mainLayout';
import { ConfigProvider } from 'antd';

function App() {
    return (
        <div className="App">
            <ConfigProvider
                theme={{
                    token: {
                        // Seed Token
                        colorPrimary: '#E9B85E',
                        borderRadius: 2,
                    },
                }}
            >
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/customers" element={<MainLayout><Customer /> </MainLayout>} />
                    <Route path="/applications" element={<MainLayout><CustomerApplications /></MainLayout>} />
                    <Route path="/applications/:id" element={<MainLayout><CustomerApplications /></MainLayout>} />
                    <Route path="/application/payments" element={<MainLayout><CustomerPayments /></MainLayout>} />
                    <Route path="/application/:id/payments" element={<MainLayout><CustomerPayments /></MainLayout>} />
                    <Route path="/application/:id/documents" element={<MainLayout><CustomerApplicationDocuments /></MainLayout>} />
                </Routes>
            </ConfigProvider>
        </div>
    );
}

export default App;
