import React, { useEffect, useState } from 'react';
import { Layout, message, theme, Table, Button } from 'antd';
import { formatDateTime, isNotNullUndefinedBlank } from "../helpers/common";
import { LeftCircleFilled, CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from "react-router-dom";
import { useGetApplicationDocuments } from '../services/customerapplication.service';
import IResponse from "../types/response";
import { ICustomerApplicationDocument } from "../types/customerapplicationdocument";

const { Column } = Table;

const CustomerApplicationDocuments: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { token: { colorBgContainer, borderRadiusLG }, } = theme.useToken();
    const [documents, setDocuments] = useState<ICustomerApplicationDocument[]>([]);
    const [applicationCode, setApplicationCode] = useState<string>("");
    const [applicationName, setApplicationName] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
        getDocuments.mutate(id);
    }, []);

    const getDocuments = useGetApplicationDocuments({
        onSuccess: (response: IResponse) => {
            if (response != null && response.data) {
                var uploadedDocuments = response.data.filter((x: { filePath: string; }) => x.filePath !== '' && x.filePath !== null && x.filePath !== undefined);
                uploadedDocuments.unshift({
                    key: 'blank-row-1',
                    applicationDocumentId: '',
                    documentId: '',
                    documentName: '',
                    applicationId: '',
                    companyName: 'Uploaded Documents',
                    email: '',
                    fileName: '',
                    filePath: '',
                    isRequired: false,
                    uploadedDate: null
                });

                var requiredNotUploadedDocuments = response.data.filter((x: { isRequired: boolean; filePath: string; }) => x.isRequired === true && (x.filePath === '' || x.filePath === null || x.filePath === undefined));
                requiredNotUploadedDocuments.unshift({
                    key: 'blank-row-2',
                    applicationDocumentId: '',
                    documentId: '',
                    documentName: '',
                    applicationId: '',
                    companyName: 'Pending Required Documents',
                    email: '',
                    fileName: '',
                    filePath: '',
                    isRequired: false,
                    uploadedDate: null
                });
                var mergedDocuments = [...uploadedDocuments, ...requiredNotUploadedDocuments];
                setDocuments(mergedDocuments);
                setApplicationCode(response.data[0].applicationCode);
                setApplicationName(response.data[0].applicationName);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        },
    });

    const data = documents.map((item) => ({
        key: item.applicationDocumentId,
        documentId: item.documentId,
        documentName: isNotNullUndefinedBlank(item.fileName) ? item.fileName : (item.companyName === "Uploaded Documents" || item.companyName === "Pending Required Documents" ? "" : (item.documentName + " *")),
        applicationId: item.applicationId,
        applicationCode: item.applicationCode,
        applicationName: item.applicationName,
        companyName: item.companyName,
        email: item.email,
        fileName: item.fileName,
        filePath: item.filePath,
        isRequired: item.isRequired,
        uploadedDate: (item.uploadedDate != null) ? formatDateTime(item.uploadedDate, "MM-DD-YYYY HH:mm AMPM") : ""
    }));

    const onBackToCustomerApplications = (record: any) => {
        localStorage.setItem('selectedKey', "3");
        if (record) {
            localStorage.setItem('selectedDocumentId', record.documentId);
        }

        const applicationpagePath = localStorage.getItem('customerApplicationPagePath') ?? "/applications";
        navigate(applicationpagePath);
    };

    // const renderColSpan = (record: any) => {
    //     if (record.companyName === "Uploaded Documents" || record.companyName === "Pending Required Documents") {
    //         return { colSpan: 4 };
    //     }
    //     return { colSpan: 1 };
    // };

    const renderColSpan = (record: any) => {
        if (record.companyName === "Uploaded Documents") {
            return { colSpan: 4, className: 'uploade-text' };
        } else if (record.companyName === "Pending Required Documents") {
            return { colSpan: 4, className: 'required-text' };
        }
        return { colSpan: 1 };
    };

    return (
        <div>
            <Layout>
                <div className='container' style={{ background: colorBgContainer, borderRadius: borderRadiusLG, }}>
                    <div className='add-customer-btn'>
                        {documents ? <div className='customer-name'>
                            <h2>#{applicationCode} - {applicationName} </h2>
                        </div> : ""}

                        <Button className='second-btn' onClick={onBackToCustomerApplications}> <LeftCircleFilled />Back to Customer Application</Button>
                    </div>
                    <Table dataSource={data}>
                        <Column
                            title="Company Name"
                            dataIndex="companyName"
                            key="companyName"
                            onCell={renderColSpan}
                            render={(text, record) => (
                                record.companyName === "Uploaded Documents" ?
                                    <span><CheckCircleOutlined /> {text}</span> : record.companyName === "Pending Required Documents" ? <span><QuestionCircleOutlined /> {text}</span> : <span>{text}</span>
                            )}
                        />
                        <Column
                            title="Customer Email ID"
                            dataIndex="email"
                            key="email"
                            onCell={(record) => ({
                                colSpan: record.companyName === "Uploaded Documents" || record.companyName === "Pending Required Documents" ? 0 : 1
                            })}
                        />
                        <Column
                            title="Document Name"
                            dataIndex="documentName"
                            key="documentName"
                            onCell={(record) => ({
                                colSpan: record.companyName === "Uploaded Documents" || record.companyName === "Pending Required Documents" ? 0 : 1
                            })}
                            render={(text, record) => (
                                <>
                                    {text} {' '}
                                    {record.filePath && (
                                        <a href={record.filePath} target="_blank" rel="noopener noreferrer">
                                            (view)
                                        </a>
                                    )}
                                </>
                            )}
                        />
                        <Column
                            title="Uploaded At (UTC)"
                            dataIndex="uploadedDate"
                            key="uploadedDate"
                            onCell={(record) => ({
                                colSpan: record.companyName === "Uploaded Documents" || record.companyName === "Pending Required Documents" ? 0 : 1
                            })}
                        />
                    </Table>
                </div>
            </Layout>
        </div>
    );
}

export default CustomerApplicationDocuments;
