import { get, patch, post } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useCreateCustomer = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("customer", data);
    }, options);
};

export const useUpdateCustomer = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("customer/" + data.customerId, data);
    }, options);
};

export const useGetCustomer = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("customer/get?customerId=" + data.customerId);
    }, options);
};

export const useDeleteCustomer = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (customerId: any) => {
        return await patch("customer/" + customerId + "/delete", null);
    }, options);
};