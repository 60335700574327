import { isValidJSON } from "../helpers/common";

const storagePrefix = 'seiler_';

const storage = {
    getToken: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}token`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}token`)) : "";
    },
    setToken: (token) => {
        window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
    },
    clearToken: () => {
        window.localStorage.removeItem(`${storagePrefix}token`);
    },
    getRefreshToken: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}refreshToken`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}refreshToken`)) : "";
    },
    setRefreshToken: (token) => {
        window.localStorage.setItem(`${storagePrefix}refreshToken`, JSON.stringify(token));
    },
    clearRefreshToken: () => {
        window.localStorage.removeItem(`${storagePrefix}refreshToken`);
    },
    getUserId: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}userId`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}userId`)) : "";
    },
    setUserId: (userId) => {
        window.localStorage.setItem(`${storagePrefix}userId`, JSON.stringify(userId));
    },
    clearUserId: () => {
        window.localStorage.removeItem(`${storagePrefix}userId`);
    },
    getUserRoleId: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}userRoleId`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}userRoleId`)) : "";
    },
    setUserRoleId: (userRoleId) => {
        window.localStorage.setItem(`${storagePrefix}userRoleId`, JSON.stringify(userRoleId));
    },
    clearUserRoleId: () => {
        window.localStorage.removeItem(`${storagePrefix}userRoleId`);
    },
    getUserEmail: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}userEmail`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}userEmail`)) : "";
    },
    setUserEmail: (userId) => {
        window.localStorage.setItem(`${storagePrefix}userEmail`, JSON.stringify(userId));
    },
    getUserName: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}userName`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}userName`)) : "";
    },
    setUserName: (userId) => {
        window.localStorage.setItem(`${storagePrefix}userName`, JSON.stringify(userId));
    }
};

export {
    storage
};