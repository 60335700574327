import { storage } from "../utils/storage";

//const APIUrl = "https://localhost:55038/api/";
const APIUrl = "https://seilerapi.onviqa.com/api/";
export function apiUrl(endpoint: string): string {
    let url = `${APIUrl}${endpoint}`;
    return url;
};

export const awsS3config = {
    bucketName: 'seilertucker-develop',
    region: 'ap-south-1',
    accessKeyId: 'AKIAVKEOVRYOO2J3ZJJP',
    secretAccessKey: '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'
};

type Headers = {
    'Content-Type': string;
    Authorization?: string;
    refreshToken?: string;
};

export async function post(url: string, data: any, withToken: boolean = true): Promise<any> {
    const headers: Headers = {
        'Content-Type': 'application/json',
    };
    if (withToken) {
        headers.Authorization = `Bearer ${storage.getToken()}`;
    }
    return fetch(apiUrl(url), {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
    }).then((response) => response.json());
}

export async function patch(url: string, data: any, withToken: boolean = true): Promise<any> {
    const headers: Headers = {
        'Content-Type': 'application/json',
    };
    if (withToken) {
        headers.Authorization = `Bearer ${storage.getToken()}`;
    }
    return fetch(apiUrl(url), {
        method: 'PATCH',
        headers,
        body: JSON.stringify(data),
    }).then((response) => response.json());
}

export async function put(url: string, data: any, withToken: boolean = true): Promise<any> {
    const headers: Headers = {
        'Content-Type': 'application/json',
    };
    if (withToken) {
        headers.Authorization = `Bearer ${storage.getToken()}`;
    }
    return fetch(apiUrl(url), {
        method: 'PUT',
        headers,
        body: JSON.stringify(data),
    }).then((response) => response.json());
}

export async function get(url: string): Promise<any> {
    const response = await fetch(apiUrl(url), {
        headers: {
            Authorization: `Bearer ${storage.getToken()}`,
            refreshToken: `${storage.getRefreshToken()}`
        },
    });
    return response.json();
}

export async function deleteRequest(url: string, withToken: boolean = true): Promise<any> {
    const headers: Headers = {
        'Content-Type': 'application/json',
    };
    if (withToken) {
        headers.Authorization = `Bearer ${storage.getToken()}`;
    }
    return fetch(apiUrl(url), {
        method: 'DELETE',
        headers,
    }).then((response) => response.json());
}