import React, { useEffect, useState, useRef } from 'react';
import { CloseOutlined, EditOutlined, DeleteOutlined, FundViewOutlined, UploadOutlined, DollarOutlined, CheckCircleOutlined, ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { Layout, theme, message, Space, Table, Drawer, Col, Button, Form, Input, Row, Select, Modal, Upload, Tooltip, Divider } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { useGetApplications, useGetApplicationsByCustomerID, useCreateCustomerApplication, useUpdateCustomerApplication, useDeleteCustomerApplication, useGetDocuments, useUploadCustomerApplicationDocuments } from '../services/customerapplication.service';
import IResponse from "../types/response";
import { ICustomerApplication, IDocuments } from "../types/customerapplication";
import { ICustomer } from '../types/customer';
import { useGetCustomer } from '../services/customer.service';
import { storage } from '../utils/storage';
import { isNotNullUndefinedBlank, formatDateTime, isNotNullUndefined } from '../helpers/common';
import { PlusCircleFilled, LeftCircleFilled } from '@ant-design/icons';
import { DeleteObjectCommand, DeleteObjectCommandInput, S3, ObjectCannedACL } from '@aws-sdk/client-s3';
import { Upload as S3Upload } from '@aws-sdk/lib-storage';
import { awsS3config } from '../helpers/config';
import { SizeType } from 'antd/es/config-provider/SizeContext';

const { Column } = Table;

const CustomerApplications = () => {
    const { id } = useParams<{ id: string }>();
    const [customerId, setCustomerId] = useState<string | undefined>(id);
    const { token: { colorBgContainer, borderRadiusLG }, } = theme.useToken();
    const [customerApplications, setCustomerApplications] = useState<ICustomerApplication[]>([]);
    const [documents, setDocuments] = useState<IDocuments[]>([]);
    const [document, setDocument] = useState<IDocuments>();
    const [documentIndex, setDocumentIndex] = useState(Number);
    const [open, setOpen] = useState(false);
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [form] = Form.useForm();
    const [isEdit, setIsEdit] = useState(false);
    const [modalType, setModalType] = useState<'delete' | 'update' | 'add' | 'deleteFile' | ''>('');
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const formInitialValues = useRef<any>({});
    const formUpdatedValues = useRef<any>({});
    const [formUpdateValues, setFormUpdateValues] = useState<any>({});
    const [applicationId, setApplicationId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [fileType, setFileType] = useState<string | null>(null);
    const [customerName, setCustomerName] = useState<string | undefined>("");

    const navigate = useNavigate();

    useEffect(() => {
        setCustomerId(id);
    }, [id]);

    useEffect(() => {
        if ((customerId === undefined || customerId === null || customerId === "") && storage.getUserRoleId() == 2) {
            var userId = storage.getUserId() ?? "";
            setCustomerId(userId);
        }
        else {
            fetchCustomerMutation.mutate({ customerId: "" });
            getCustomerApplications();
        }
        localStorage.setItem('customerApplicationPagePath', window.location.pathname);
    }, [customerId]);

    useEffect(() => {
        if (isNotNullUndefinedBlank(customerId) && customers.length > 0) {
            var customer = customers.find((x: any) => x.customerId === customerId);
            setCustomerName((customer?.firstName ?? "") + " " + (customer?.lastName ?? ""))
        }
        else {
            setCustomerName("All Applications")
        }
    }, [customers]);

    const fetchCustomerMutation = useGetCustomer({
        onSuccess: (response: IResponse) => {
            if (response != null && response !== undefined) {
                setCustomers(response.data);
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const getCustomerApplications = async () => {
        if (isNotNullUndefinedBlank(customerId)) {
            getApplicationsByCustomerId.mutate({ customerId: customerId });
        }
        else {
            getApplications.mutate({});
        }
    };

    const getApplications = useGetApplications({
        onSuccess: (response: IResponse) => {
            if (response != null && response.data) {
                setCustomerApplications(response.data);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const getApplicationsByCustomerId = useGetApplicationsByCustomerID({
        onSuccess: (response: IResponse) => {
            if (response != null && response.data) {
                setCustomerApplications(response.data);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const getDocuments = async () => {
        setDocuments([]);
        getDocumentMutation.mutate({});
    };

    const getDocumentMutation = useGetDocuments({
        onSuccess: (response: IResponse) => {
            if (response != null && response.data) {
                if (isNotNullUndefinedBlank(applicationId)) {
                    const application = customerApplications.find(x => x.applicationId === applicationId);
                    const applicationDocument = isNotNullUndefinedBlank(application?.applicationDocuments) ? JSON.parse(application?.applicationDocuments ?? "") : null;
                    if (isNotNullUndefined(applicationDocument) && applicationDocument.ApplicationDocuments.length > 0) {
                        response.data.forEach((x: { documentId: string; documentFileName: string; documentFilePath: string; }) => {
                            const doc = applicationDocument.ApplicationDocuments.find((y: { DocumentID: string; }) => y.DocumentID.toLowerCase() === x.documentId.toLowerCase());
                            if (doc !== null && doc !== undefined) {
                                x.documentFileName = doc.FileName;
                                x.documentFilePath = doc.FilePath;
                            }
                        });
                    }
                }
                else {
                    response.data.forEach((x: { documentId: string; documentFileName: string; documentFilePath: string; }) => {
                        x.documentFileName = "";
                        x.documentFilePath = "";
                    });
                }
                setDocuments(response.data);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const data = customerApplications.map((item) => ({
        key: item.applicationId,
        CustomerID: item.customerId,
        Email: item.email,
        ApplicationCode: item.applicationCode,
        ApplicationName: item.applicationName,
        CompanyName: item.companyName,
        DocumentStatus: item.documentStatus,
        PaymentStatus: item.paymentStatus,
        ApplicationDocuments: item.applicationDocuments,
        SubmittedAt: item.submittedAt != null ? formatDateTime(new Date(item.submittedAt), "MM-DD-YYYY HH:mm AMPM") : ''
    }));

    const openDrawer = () => {
        setApplicationId("");
        getDocuments();
        setIsEdit(false);
        form.resetFields();
        setOpen(true);

        if (isNotNullUndefinedBlank(customerId)) {
            form.setFieldsValue({
                customer: customerId?.toLowerCase()
            });
        }
    };

    const closeDrawer = () => {
        form.resetFields();
        setOpen(false);
    };

    const getModalTitle = () => {
        let message = "";
        if (modalType === 'delete') {
            message = "Confirm Deletion!";
        } else if (modalType === 'update') {
            message = "Confirm Update!";
        }
        else if (modalType === 'add') {
            message = "Confirm Add!";
        }
        else if (modalType === 'deleteFile') {
            message = "Delete File!";
        }
        return message;
    }

    const handleCancel = () => {
        setIsLoading(false);
        setIsConfirmModalVisible(false);
    };

    const getModelContent = () => {
        let modalContent = <></>;
        if (modalType === 'delete') {
            modalContent = (
                <p>Are you sure you want to delete <b>{formUpdateValues.applicationName}</b>? Once deleted, the customer application will not be recovered. Do you wish to proceed?</p>
            );
        } else if (modalType === 'update') {
            modalContent = (
                <p>Are you sure you want to update <b>{formUpdateValues.applicationName}</b>? Once updated, the customer application can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        else if (modalType === 'add') {
            modalContent = (
                <p>Are you sure you want to add <b>{formUpdateValues.applicationName}</b>? Once added, the customer application can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        else if (modalType === 'deleteFile') {
            modalContent = (
                <p>Are you sure you want to delete this file? Once delete the file, you can upload new file. Do you wish to proceed?</p>
            );
        }
        return modalContent;
    }

    const addCustomerApplication = useCreateCustomerApplication({
        onSuccess: (response: IResponse) => {
            if (response !== null && response.statusCode === 200) {
                const uploadedDocuments: any = [];
                documents.forEach(x => {
                    if (isNotNullUndefinedBlank(x.documentFilePath)) {
                        uploadedDocuments.push({
                            applicationId: response.data.applicationId,
                            documentId: x.documentId,
                            fileName: x.documentFileName,
                            filePath: x.documentFilePath
                        })
                    }
                });
                uploadCustomerApplicationDocumentMutation.mutate(uploadedDocuments);
                getCustomerApplications();
                message.success(response.statusMessage);
                closeDrawer();
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                message.warning(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            message.error(error.message);
        },
    });

    const updateCustomerApplication = useUpdateCustomerApplication({
        onSuccess: (response: IResponse) => {
            if (response !== null && response.statusCode === 200) {
                const uploadedDocuments: any = [];
                documents.forEach(x => {
                    uploadedDocuments.push({
                        applicationId: applicationId,
                        documentId: x.documentId,
                        fileName: isNotNullUndefined(x.documentFileName) ? x.documentFileName : "",
                        filePath: isNotNullUndefined(x.documentFilePath) ? x.documentFilePath : ""
                    })
                });
                uploadCustomerApplicationDocumentMutation.mutate(uploadedDocuments);
                message.success(response.statusMessage);
                setIsLoading(false);
                closeDrawer();
            }
            else {
                setIsLoading(false);
                message.warning(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            message.error(error.message);
        },
    });

    const onDeleteCustomerApplication = (record: any) => {
        setModalType('delete');
        setIsConfirmModalVisible(true);
        setFormUpdateValues({ applicationName: record.ApplicationName });
        setApplicationId(record.key.toString());
    }

    const deleteCustomerApplication = useDeleteCustomerApplication({
        onSuccess: (response: IResponse) => {
            if (response !== null && response.statusCode === 200) {
                message.success(response.statusMessage);
                getCustomerApplications();
                setApplicationId("");
            }
            else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        },
    });

    const handleSave = async () => {
        try {
            if (modalType === 'delete') {
                deleteCustomerApplication.mutate(applicationId);
            }
            else if (modalType === 'add') {
                setIsLoading(true);
                addCustomerApplication.mutate({
                    customerId: formUpdateValues.customerId,
                    applicationName: formUpdateValues.applicationName,
                    companyName: formUpdateValues.companyName
                });
            }
            else if (modalType === 'update') {
                setIsLoading(true);
                updateCustomerApplication.mutate({
                    applicationId: applicationId,
                    customerId: formUpdateValues.customerId,
                    applicationName: formUpdateValues.applicationName,
                    companyName: formUpdateValues.companyName
                });
            }
            else if (modalType === 'deleteFile') {
                onDeleteFile(document, documentIndex);
            }
            setIsConfirmModalVisible(false);
        } catch (error) {
            message.error('Failed to save');
        }
    };

    const onEdit = (record: any) => {
        setApplicationId(record.key.toString());
        form.setFieldsValue({
            customer: record.CustomerID,
            applicationName: record.ApplicationName,
            companyName: record.CompanyName
        });
        getDocuments();

        formInitialValues.current = { customerId: record.CustomerID, applicationName: record.ApplicationName, companyName: record.CompanyName }
        setIsEdit(true);
        setOpen(true);
    };

    const onSubmit = async () => {
        setModalType((isEdit ? 'update' : 'add'));
        const customerId = form.getFieldValue("customer");
        const applicationName = form.getFieldValue("applicationName");
        const companyName = form.getFieldValue("companyName");

        if (customerId && applicationName && companyName) {
            const param = {
                customerId: customerId,
                applicationName: applicationName,
                companyName: companyName
            };
            if (modalType === 'update') {
                formUpdatedValues.current = { customerId: customerId, applicationName: applicationName, companyName: companyName }
                // if (compareObjects(formInitialValues.current, formUpdatedValues.current)) {
                //     setIsLoading(false);
                //     setOpen(false);
                //     return;
                // }
            }
            setFormUpdateValues(param);
            setIsConfirmModalVisible(true);
        } else {
            setIsLoading(false);
            message.error("Please fill in all mandatory fields before adding a place.")
        }
    };

    const onViewContentRepo = (record: any) => {
        navigate(`/application/${record.key}/documents`);
    };

    const handleUpload = (file: any, document: any, index: number) => {
        if (file) {
            if (isNotNullUndefinedBlank(document.documentFilePath)) {
                onDeleteFile(document, index);
            }
            //const selectedCustomerId = form.getFieldValue("customer");
            const fileName = file.name;//"customer_" + selectedCustomerId + "_" + new Date().getTime() + "." + file.name.split(".")[1];
            try {
                const bucket = new S3({
                    credentials: {
                        accessKeyId: awsS3config.accessKeyId,
                        secretAccessKey: awsS3config.secretAccessKey,
                    },
                    region: awsS3config.region,
                });

                const params = {
                    Bucket: awsS3config.bucketName,
                    Key: "temp/" + fileName,
                    Body: file,
                    ContentType: file.type,
                    ACL: ObjectCannedACL.public_read_write
                };

                const upload = new S3Upload({
                    client: bucket,
                    params: params,
                });
                upload.done().then((data) => {
                    const fileUrl = (data["Location"] !== '' && data["Location"] !== null && data["Location"] !== undefined) ? data["Location"] : "";
                    file.url = fileUrl;
                    const updatedDocument = [...documents];
                    updatedDocument[index].documentFilePath = fileUrl;
                    updatedDocument[index].documentFileName = fileName;
                    setDocuments(updatedDocument);
                    setFileType(file.type);
                },
                    (error: any) => {
                        let errorJson = JSON.stringify(error);
                        let errorObject = JSON.parse(errorJson);
                        let msg = (errorObject["message"] !== '' && errorObject["message"] !== null && errorObject["message"] !== undefined) ? errorObject["message"] :
                            'Something went wrong. Please try again!';
                        message.error(msg);
                        return false;
                    });
            } catch (error) {
                message.error("Error uploading file.");
                return false;
            }
        }
        return false;
    };

    const onDeleteFile = async (document: any, index: number) => {
        if (isNotNullUndefinedBlank(document.documentFilePath)) {
            const fileName = document.documentFilePath?.split('/').pop();
            if (isNotNullUndefinedBlank(fileName)) {
                try {
                    const s3Client = new S3({
                        credentials: {
                            accessKeyId: awsS3config.accessKeyId,
                            secretAccessKey: awsS3config.secretAccessKey,
                        },
                        region: awsS3config.region,
                    });

                    const params: DeleteObjectCommandInput = {
                        Bucket: awsS3config.bucketName,
                        Key: document.documentFilePath?.includes("/temp/") ? "temp/" + fileName : "Documents/" + fileName
                    };

                    const command = new DeleteObjectCommand(params);
                    await s3Client.send(command);
                }
                catch (error) {
                    message.error("Error uploading file.");
                    return false;
                }
                const updatedDocument = [...documents];
                updatedDocument[index].documentFilePath = "";
                updatedDocument[index].documentFileName = "";
                setDocuments(updatedDocument);
            }
        }
    };

    const onDeleteFileConfirm = (document: IDocuments, index: number) => {
        setModalType('deleteFile');
        setDocument(document);
        setDocumentIndex(index);
        setIsConfirmModalVisible(true);
    };

    const uploadCustomerApplicationDocumentMutation = useUploadCustomerApplicationDocuments({
        onSuccess: (response: IResponse) => {
            setApplicationId("");
            getCustomerApplications();
        },
        onError: (error) => {
            setIsLoading(false);
            message.error(error.message);
        },
    });

    const onBackToCustomers = (record: any) => {
        localStorage.setItem('selectedKey', "2");
        navigate(`/customers`);
    };

    const onViewPayments = (record: any) => {
        localStorage.setItem('applicationPaymentCustomerID', record.CustomerID);
        navigate(`/application/${record.key}/payments`);
    };

    return (
        <Layout>
            <div className='container' style={{ background: colorBgContainer, borderRadius: borderRadiusLG, }}>
                <div className='add-customer-btn'>
                    <div className='customer-name'>
                        <h2>{customerName}</h2>
                    </div>
                    <div className='create-back-btn'>
                        {(storage.getUserRoleId() == 1) && (
                            <Button className='second-btn' onClick={onBackToCustomers}> <LeftCircleFilled />Back to Customer List</Button>
                        )}
                        <Button type="primary" onClick={openDrawer}> <PlusCircleFilled />Create Application</Button>
                    </div>
                </div>

                <Drawer title={isEdit ? "Update Application" : "Create Application"} onClose={closeDrawer} open={open} width={650} mask={false}>
                    <Form form={form} layout="vertical" onSubmitCapture={onSubmit} className='add-payment-drawer'>
                        <div className='drawer-subtitle'>
                            <span className="title">Basic Details</span>
                        </div>
                        <Row gutter={16} className='onlyinput'>
                            <Col span={12}>
                                <Form.Item
                                    name="customer"
                                    label="Customer"
                                    rules={[{ required: true, message: 'Please select a customer.' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Please select customer"
                                        optionFilterProp="label"
                                        options={customers ? customers.map(x => ({ label: x.firstName + ' ' + x.lastName + ' (' + x.email + ')', value: x.customerId })) : []}
                                        allowClear
                                        disabled={isNotNullUndefinedBlank(customerId)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} className='onlyinput'>
                            <Col span={12}>
                                <Form.Item
                                    name="applicationName"
                                    label="Application Name"
                                    rules={[{ required: true, message: 'Please enter application name' }]}
                                >
                                    <Input placeholder="Please enter application name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="companyName"
                                    label="Company Name"
                                    rules={[{ required: true, message: 'Please enter company name' }]}
                                >
                                    <Input placeholder="Please enter company name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider style={{ borderColor: '#e9b85e' }} className="divider" />
                        <div className='drawer-subtitle'>
                            <span className="title">Required Documents</span>
                        </div>
                        <Row gutter={16}>
                            {documents && documents.length > 0 &&
                                documents.filter(x => x.isRequired).map((x, index) => (
                                    <Col span={12} key={x.documentId} className='upload-btn'>
                                        <Form.Item name={x.documentName} label={x.documentName} rules={[{ required: Boolean(x.isRequired) }]}>

                                            <Upload beforeUpload={(e) => handleUpload(e, x, index)} showUploadList={false} accept="image/*,.pdf,.xls">
                                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                        {isNotNullUndefinedBlank(x.documentFileName) ?
                                            <span className='upload-box'>
                                                <p>{x.documentFileName}</p>
                                                <Button className="delete-btn" onClick={() => onDeleteFileConfirm(x, index)}><DeleteOutlined /></Button>
                                            </span> : ""}
                                    </Col>
                                ))
                            }
                        </Row>
                        <Divider style={{ borderColor: '#e9b85e' }} className="divider" />
                        <div className='drawer-subtitle'>
                            <span className="title">Optional Documents</span>
                        </div>
                        <Row gutter={16}>
                            {documents && documents.length > 0 &&
                                documents.filter(x => !x.isRequired).map((x, index) => (
                                    <Col span={12} key={x.documentId} className='upload-btn'>

                                        <Form.Item name={x.documentName} label={x.documentName} rules={[{ required: Boolean(x.isRequired) }]}>

                                            <Upload beforeUpload={(e) => handleUpload(e, x, index)} showUploadList={false} accept="image/*,.pdf,.xls">
                                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                        {isNotNullUndefinedBlank(x.documentFileName) ?
                                            <span className='upload-box'>
                                                <p>{x.documentFileName}</p>
                                                <Button className="delete-btn" onClick={() => onDeleteFileConfirm(x, index)}><DeleteOutlined /></Button>
                                            </span> : ""}
                                    </Col>
                                ))
                            }
                        </Row>
                        {/* {!isEdit ? <Row gutter={16}>
                            <Col span={12}>
                                {documents ? documents.map((x, index) => (
                                    <Col span={12} key={x.documentId}>
                                        <p>{x.documentName} {x.isRequired ? "(Required)" : "(Not Required)"}</p>
                                        <Upload beforeUpload={(e) => handleUpload(e, x, index)} showUploadList={false} accept="image/*,.pdf,.xls">
                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                        </Upload>
                                        {isNotNullUndefinedBlank(x.documentFileName) ?
                                            <span>
                                                <p>{x.documentFileName}</p>
                                                <Button onClick={() => onDeleteFileConfirm(x, index)}>Cancel</Button>
                                            </span> : ""}
                                    </Col>
                                )) : []}
                            </Col>
                        </Row> : ""} */}
                        <Space className='add-payment-btn creat-app'>
                            <Button onClick={closeDrawer} size="large"><CloseOutlined />Cancel</Button>
                            <Button htmlType="submit" loading={isLoading} type="primary" size="large" ><CheckOutlined />{isEdit ? "Update" : "Create"}</Button>
                        </Space>
                    </Form>
                </Drawer>

                <Modal
                    title={<span className="custom-modal-title">{getModalTitle()}</span>}
                    open={isConfirmModalVisible}
                    onOk={handleSave}
                    onCancel={handleCancel}
                    closeIcon={<CloseOutlined className="text-gray-500 hover:text-black" />}
                    footer={[
                        <Space className='add-payment-btn'>
                            <Button key="back" onClick={handleCancel} className="hover:!text-black hover:!border-black">
                                Cancel
                            </Button>
                            <Button type='primary' key="submit" onClick={handleSave} className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                                Confirm
                            </Button>
                        </Space>
                    ]}
                >
                    {getModelContent()}
                </Modal>

                <Table dataSource={data}>
                    <Column title="Application #" dataIndex="ApplicationCode" key="applicationCode" />
                    <Column title="Application Name" dataIndex="ApplicationName" key="applicationName" />
                    <Column title="Email" dataIndex="Email" key="email" />
                    <Column title="Company Name" dataIndex="CompanyName" key="companyName" />
                    <Column title="Document Status" dataIndex="DocumentStatus" key="documentStatus" className='status-icon'
                        render={(text) => {
                            let className = '';
                            if (text === 'Pending') {
                                className = 'warning';
                            } else if (text === 'Completed') {
                                className = 'success';
                            }
                            return text === "Completed" ? <span className={className}><CheckCircleOutlined />{text}</span> : <span className={className}><ExclamationCircleOutlined />{text}</span>;
                        }} />
                    <Column title="Payment Status" dataIndex="PaymentStatus" key="paymentStatus" className='status-icon'
                        render={(text) => {
                            let className = '';
                            if (text === 'Pending') {
                                className = 'warning';
                            } else if (text === 'Completed') {
                                className = 'success';
                            }
                            return text === "Completed" ? <span className={className}><CheckCircleOutlined />{text}</span> : <span className={className}><ExclamationCircleOutlined />{text}</span>;
                        }} />
                    <Column title="Submitted At (UTC)" dataIndex="SubmittedAt" key="submittedAt" />
                    <Column
                        title="Action"
                        className='table-icon'
                        key="action"
                        render={(_: any, record: ICustomerApplication) => (
                            <Space size="middle">
                                <Tooltip title="Update Application">
                                    <Button icon={<EditOutlined />} onClick={() => onEdit(record)}></Button>
                                </Tooltip>
                                <Tooltip title="Delete Application">
                                    <Button icon={<DeleteOutlined />} onClick={() => onDeleteCustomerApplication(record)}></Button>
                                </Tooltip>
                                <Tooltip title="View Content Repo">
                                    <Button icon={<FundViewOutlined />} onClick={() => onViewContentRepo(record)}></Button>
                                </Tooltip>
                                <Tooltip title="Payments">
                                    <Button icon={<DollarOutlined />} onClick={() => onViewPayments(record)}></Button>
                                </Tooltip>

                            </Space>
                        )}
                    />
                </Table>

            </div>
        </Layout >
    )
}

export default CustomerApplications