import { storage } from "../utils/storage";

export const isNotNullUndefinedBlank = (data: any) => {
    return (data !== null && data !== undefined && data.toString().trim() !== "");
};

export const isNotNullUndefined = (data: any) => {
    return (data !== null && data !== undefined);
};

export const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
};

export const isValidPhoneNumber = (number: any) => {
    return /^\d{10}$/.test(number);
};

export const isValidJSON = (jsonString: string): boolean => {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (error) {
        console.warn(error);
        return false;
    }
};

export const formatDateTime = (value: number | Date, format: string) => {
    let date = new Date(value);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    // Define format patterns
    const patterns = {
        "DD": () => String(date.getDate()).padStart(2, '0'),                   // Day of the month (01-31)
        "MM": () => (date.getMonth() + 1).toString().padStart(2, '0'),         // Month abbreviation (Jan-Dec)
        "MMM": () => monthNames[date.getMonth()],                              // Month abbreviation (Jan-Dec)
        "YYYY": () => String(date.getFullYear()),                              // Full year (e.g., 2023)
        "hh": () => String(date.getHours()).padStart(2, '0'),                  // Hours (00-23)
        "HH": () => String(date.getHours() % 12 || 12).padStart(2, '0'),       // Hours (01-12)
        "mm": () => String(date.getMinutes()).padStart(2, '0'),                // Minutes (00-59)
        "AMPM": () => date.getHours() >= 12 ? 'PM' : 'AM',                     // AM/PM
    };

    const sortedPatterns = Object.entries(patterns).sort(([a], [b]) => b.length - a.length);

    // Replace format patterns with corresponding values
    let formattedDate = format;
    for (const [pattern, replacer] of sortedPatterns) {
        formattedDate = formattedDate.replace(pattern, replacer());
    }
    return formattedDate;
};

export const compareObjects = (object1: any, object2: any): boolean => {
    const keys1 = Object.keys(object1).sort();
    const keys2 = Object.keys(object2).sort();
    if (keys1.length !== keys2.length) {
        // console.warn('Different number of keys');
        return false;
    }

    for (const key of keys1) {
        const value1 = object1[key];
        const value2 = object2[key];

        if (Array.isArray(value1) && Array.isArray(value2)) {
            if (value1.length !== value2.length) {
                // console.warn(`Array length mismatch for key ${key}`);
                return false;
            }
            for (let i = 0; i < value1.length; i++) {
                if (!compareObjects(value1[i], value2[i])) {
                    // console.warn(`Array element mismatch at index ${i} for key ${key}`);
                    return false;
                }
            }
        } else if (value1 instanceof Date && value2 instanceof Date) {
            if (value1.getTime() !== value2.getTime()) {
                // console.warn(`Date mismatch for key ${key}`);
                return false;
            }
        } else if (typeof value1 === 'object' && value1 !== null && typeof value2 === 'object' && value2 !== null) {
            if (!compareObjects(value1, value2)) {
                // console.warn(`Object mismatch for key ${key}`);
                return false;
            }
        } else if (value1 !== value2) {
            // console.warn(`Primitive mismatch for key ${key}: ${value1} !== ${value2}`);
            return false;
        }
    }
    return true;
};

