import { get, patch, post } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useGetPayments = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("customer/application/payments");
    }, options);
};

export const useGetPaymentsByApplicationID = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("customer/application/payments?applicationId=" + data.applicationId);
    }, options);
};

export const useGetPaymentsByCustomerID = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("customer/application/payments?customerId=" + data.customerId);
    }, options);
};

export const useDeletePayment = (options: any) => {
    return useMutation(async (paymentId) => {
        return await patch(`customer/application/payment/${paymentId}/delete`, {});
    }, options);
};

export const useAddPayment = (options: any) => {
    return useMutation(async (data: any) => {
        return await post(`customer/application/payment`, data);
    }, options);
};