import React, { useEffect, useRef, useState } from 'react';
import { CloseOutlined, EditOutlined, DeleteOutlined, FundViewOutlined } from '@ant-design/icons';
import { Layout, message, theme, Form, Modal, Drawer, Row, Col, Input, Button, Tooltip } from 'antd';
import { Space, Table } from 'antd';
import { compareObjects, formatDateTime, isNotNullUndefined, isValidPhoneNumber } from '../helpers/common';
import { ICustomer } from '../types/customer';
import { useCreateCustomer, useDeleteCustomer, useGetCustomer, useUpdateCustomer } from '../services/customer.service';
import IResponse from '../types/response';
import { useNavigate } from "react-router-dom";
import { PlusCircleFilled, CheckOutlined } from '@ant-design/icons';

const { Column } = Table;
interface DataType {
    key: React.Key;
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    status: string;
    registeredOn: string
};

const CustomerList = () => {
    const navigate = useNavigate();

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [form] = Form.useForm();
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modalType, setModalType] = useState<'delete' | 'update' | 'add' | ''>('');
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [customerId, setCustomerId] = useState('');
    const [formUpdateValues, setFormUpdateValues] = useState<any>({});
    const formInitialValues = useRef<any>({});
    const formUpdatedValues = useRef<any>({});

    useEffect(() => {
        getCustomers("");
    }, []);

    const getCustomers = async (customerId: string) => {
        getCustomersMutation.mutate({ customerId: customerId });
    };

    const getCustomersMutation = useGetCustomer({
        onSuccess: (response: IResponse) => {
            if (isNotNullUndefined(response) && response.data) {
                setIsLoading(false);
                setCustomers(response.data);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const formatPhoneNumber = (value: any) => {
        const cleanedValue = value.replace(/\D/g, '');
        const match = cleanedValue.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
        }
        return value;
    };

    const data = customers.map((item) => ({
        key: item.customerId,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        phoneNo: item.phoneNo,
        phoneNoFormat: formatPhoneNumber(item.phoneNo),
        status: item.status,
        registeredOn: item.registeredOn != null ? formatDateTime(new Date(item.registeredOn), "MM-DD-YYYY HH:mm AMPM") : '',
        loginMode: "ST web Application"
    }));

    const openDrawer = () => {
        setIsEdit(false);
        form.resetFields();
        setOpen(true);
    };

    const closeDrawer = () => {
        form.resetFields();
        setOpen(false);
    };

    const createCustomer = useCreateCustomer({
        onSuccess: (response: IResponse) => {
            if (response !== null && response.statusCode === 200) {
                getCustomers("");
                message.success(response.statusMessage);
                closeDrawer();
            }
            else {
                setIsLoading(false);
                message.warning(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            message.error(error.message);
        },
    });

    const updateCustomer = useUpdateCustomer({
        onSuccess: (response: IResponse) => {
            if (response !== null && response.statusCode === 200) {
                getCustomers("");
                message.success(response.statusMessage);
                closeDrawer();
            }
            else {
                setIsLoading(false);
                message.warning(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            message.error(error.message);
        },
    });

    const onDeleteCustomer = (record: any) => {
        setModalType('delete');
        setIsConfirmModalVisible(true);
        setCustomerId(record.key.toString());
    }

    const deleteCustomer = useDeleteCustomer({
        onSuccess: (response: IResponse) => {
            if (response !== null && response.statusCode === 200) {
                message.success(response.statusMessage);
                getCustomers("");
            }
            else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        },
    });

    const onEdit = (record: any) => {
        form.setFieldsValue({
            customerId: record.key,
            firstName: record.firstName,
            lastName: record.lastName,
            email: record.email,
            phoneNo: record.phoneNo
        });

        formInitialValues.current = { firstName: record.firstName, lastName: record.lastName, email: record.email, phoneNo: record.phoneNo };
        setIsEdit(true);
        setOpen(true);
    }

    const onSubmit = async () => {
        setModalType((isEdit ? 'update' : 'add'));
        if (modalType === 'update') {
            formUpdatedValues.current = { firstName: form.getFieldValue("firstName"), lastName: form.getFieldValue("lastName"), email: form.getFieldValue("email"), phoneNo: form.getFieldValue("phoneNo") }
            if (compareObjects(formInitialValues.current, formUpdatedValues.current)) {
                setIsLoading(false);
                setOpen(false);
                return;
            }
        }

        if ((form.getFieldValue("phoneNo") !== "" && !isValidPhoneNumber(form.getFieldValue("phoneNo")))) {
            message.error("Please enter a valid phone number");
            return;
        }

        if (isEdit) {
            const param = {
                customerId: form.getFieldValue("customerId"),
                firstName: form.getFieldValue("firstName"),
                lastName: form.getFieldValue("lastName"),
                email: form.getFieldValue("email"),
                phoneNo: form.getFieldValue("phoneNo")
            };
            setFormUpdateValues(param);
        }
        else {
            const param = {
                firstName: form.getFieldValue("firstName"),
                lastName: form.getFieldValue("lastName"),
                email: form.getFieldValue("email"),
                phoneNo: form.getFieldValue("phoneNo"),
                password: form.getFieldValue("password")
            };
            setFormUpdateValues(param);
        }
        setIsConfirmModalVisible(true);
    };

    const getModalTitle = () => {
        let message = "";
        if (modalType === 'delete') {
            message = "Confirm Deletion!";
        } else if (modalType === 'update') {
            message = "Confirm Update!";
        }
        else if (modalType === 'add') {
            message = "Confirm Add!";
        }
        return message;
    };

    const getModelContent = () => {
        let modalContent = <></>;
        if (modalType === 'delete') {
            modalContent = (
                <p>Are you sure you want to delete <b>{formUpdateValues.firstName} {formUpdateValues.lastName}</b>? Once deleted, the customer will not be recovered. Do you wish to proceed?</p>
            );
        } else if (modalType === 'update') {
            modalContent = (
                <p>Are you sure you want to update <b>{formUpdateValues.firstName} {formUpdateValues.lastName}</b>? Once updated, the customer can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        else if (modalType === 'add') {
            modalContent = (
                <p>Are you sure you want to add <b>{formUpdateValues.firstName} {formUpdateValues.lastName}</b>? Once added, the customer can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        return modalContent;
    }

    const handleSave = async () => {
        try {
            if (modalType === 'delete') {
                deleteCustomer.mutate(customerId);
            }
            else if (modalType === 'add') {
                setIsLoading(true);
                createCustomer.mutate({
                    firstName: formUpdateValues.firstName,
                    lastName: formUpdateValues.lastName,
                    email: formUpdateValues.email,
                    phoneNo: formUpdateValues.phoneNo,
                    password: formUpdateValues.password
                });
            }
            else if (modalType === 'update') {
                updateCustomer.mutate({
                    customerId: formUpdateValues.customerId,
                    firstName: formUpdateValues.firstName,
                    lastName: formUpdateValues.lastName,
                    email: formUpdateValues.email,
                    phoneNo: formUpdateValues.phoneNo
                });
            }
            setIsConfirmModalVisible(false);
        } catch (error) {
            message.error('Failed to create/update customer.');
        }
    };

    const handleCancel = () => {
        setIsLoading(false);
        setIsConfirmModalVisible(false);
    };

    const onViewApplications = (record: any) => {
        navigate(`/applications/${record.key}`);
    };

    return (
        <Layout>
            <div className='container'
                style={{ background: colorBgContainer, borderRadius: borderRadiusLG }}>

                <div className='add-customer-btn'>
                    <div className='customer-name'>
                        <h2>All Customers</h2>
                    </div>
                    <Button type="primary" onClick={openDrawer}> <PlusCircleFilled />Create Customer</Button>
                </div>

                <Table dataSource={data}>
                    <Column title="First Name" dataIndex="firstName" key="firstName" />
                    <Column title="Last Name" dataIndex="lastName" key="lastName" />
                    <Column title="Email" dataIndex="email" key="email" />
                    <Column title="Phone Number" dataIndex="phoneNoFormat" key="phoneNo" />
                    <Column title="Status" dataIndex="status" key="status" className='success' />
                    <Column title="Registered On (UTC)" dataIndex="registeredOn" key="registeredOn" />
                    {/*<Column title="Login Mode" dataIndex="loginMode" key="loginMode" />*/}
                    <Column
                        title="Action"
                        key="action"
                        className='table-icon'
                        render={(_: any, record: DataType) => (
                            record.status === "Active" ? <Space size="middle">
                                <Tooltip title="Edit Customer">
                                    <Button icon={<EditOutlined />} onClick={() => onEdit(record)}></Button>
                                </Tooltip>
                                <Tooltip title="Delete Customer">
                                    <Button icon={<DeleteOutlined />} onClick={() => onDeleteCustomer(record)}></Button>
                                </Tooltip>
                                <Tooltip title="Applications">
                                    <Button icon={<FundViewOutlined />} onClick={() => onViewApplications(record)}></Button>
                                </Tooltip>
                            </Space> : ""
                        )}
                    />
                </Table>
            </div>
            <Drawer
                title={isEdit ? "Update Customer" : "Create Customer"}
                width={400}
                onClose={closeDrawer}
                open={open}
                mask={false}
                bodyStyle={{ paddingBottom: 80 }}>
                <Form layout="vertical" form={form} onSubmitCapture={onSubmit} className='add-payment-drawer'>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="cutomerId"
                                label="Customer ID"
                                style={{ display: 'none' }}>
                                <Input type="hidden" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="firstName"
                                label="First Name"
                                rules={[{ required: true, message: 'Please enter first name' }]}
                            >
                                <Input size="large" placeholder="Please enter first name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="lastName"
                                label="Last Name"
                                rules={[{ required: true, message: 'Please enter last name' }]}
                            >
                                <Input size="large" placeholder="Please enter last name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter email'
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address'
                                    }
                                ]}
                            >
                                <Input size="large" placeholder="Please enter email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="phoneNo"
                                label="Phone No"
                                rules={[{ required: true, message: 'Please enter phone no' }]}
                            >
                                <Input size="large" placeholder="Please enter phone no" maxLength={10} onChange={(e) => {
                                    const value = e.target.value.replace(/[^0-9]/g, '');
                                    form.setFieldsValue({ phoneNo: value });
                                }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        !isEdit ? <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="password"
                                    label="Login Password"
                                    className='customer-password'
                                >
                                    <Input.Password size="large" placeholder="Please enter password" />
                                </Form.Item>
                            </Col>
                        </Row> : ""
                    }
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="message" label={<span style={{ color: 'red', fontSize: 11, fontStyle: 'italic' }}>Note: Customer can Login using the above Email and Password. If Password is blank, user won't be created.</span>}></Form.Item>
                        </Col>
                    </Row>
                    <Space className='add-payment-btn'>
                        <Button onClick={closeDrawer} size="large"><CloseOutlined />Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading} size="large"><CheckOutlined />{isEdit ? "Update" : "Create"}</Button>
                    </Space>
                </Form>
            </Drawer>

            <Modal
                title={<span className="custom-modal-title">{getModalTitle()}</span>}
                open={isConfirmModalVisible}
                onOk={handleSave}
                onCancel={handleCancel}
                closeIcon={<CloseOutlined className="text-gray-500 hover:text-black" />}
                footer={[
                    <Space className='add-payment-btn'>
                        <Button key="back" onClick={handleCancel} className="hover:!text-black hover:!border-black">
                            Cancel
                        </Button>
                        <Button type='primary' key="submit" onClick={handleSave} className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                            Confirm
                        </Button>
                    </Space>
                ]}
            >
                {getModelContent()}
            </Modal>
        </Layout>
    )
}

export default CustomerList