import { useState } from 'react';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Flex, Card } from 'antd';
import { storage } from '../utils/storage';
import { useAuthenticate } from '../services/login.service';
import { isNotNullUndefinedBlank } from '../helpers/common';
import IResponse from '../types/response';
import logo from '../images/seiler_Tucker_logo.png';
import security from '../images/security.svg';
import { Footer } from 'antd/es/layout/layout';

const Login = () => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isEmailTyped, setIsEmailTyped] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onChangeInputValue = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        if (value.length >= 3) {
            if (/^[0-9]+$/.test(value)) {
                setEmail('');
                setIsEmailTyped(false);
            } else {
                setEmail(value);
                setIsEmailTyped(true);
            }
        } else if (value.length === 0) {
            setEmail('');
            setIsEmailTyped(false);
        }
    };

    const authenticateMutation = useAuthenticate({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                storage.setToken(response.data.token);
                storage.setRefreshToken(response.data.refreshToken);
                storage.setUserRoleId(response.data.userRoleId);
                storage.setUserId(response.data.userId);
                storage.setUserEmail(response.data.email);
                storage.setUserName((response.data.firstName ?? "") + " " + (response.data.lastName ?? ""));
                if (response.data.userRoleId === 1) {
                    message.success('You have logged in successfully!');
                    navigate("/customers");
                    localStorage.setItem('selectedKey', "2");
                }
                else if (response.data.userRoleId === 2) {
                    message.success('You have logged in successfully!');
                    navigate("/applications");
                    localStorage.setItem('selectedKey', "3");
                }
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    const onAuthenticate = async () => {
        if (isNotNullUndefinedBlank(email)) {
            authenticateMutation.mutate({ email: email, password: password });
        }
    };

    return (
        <div className='login-page'>
            <div className='login-container'>
                <Card title="" bordered={false} style={{ width: 350 }}>
                    <Form name="" className='login-form' initialValues={{ remember: true }} style={{ maxWidth: 360 }} onFinish={onAuthenticate}>
                        <div className="demo-logo-vertical login-logo">
                            <img width={240} src={logo} title='Logo' />
                        </div>
                        <Form.Item name="email" id="email" rules={[{ required: true, message: "Please input your E-mail!", },]}>
                            <Input
                                onChange={onChangeInputValue}
                                autoFocus
                                placeholder="Enter your email"
                                prefix={<UserOutlined />}
                            />
                        </Form.Item>
                        <Form.Item
                            className="font-semibold"
                            name="password"
                            rules={[{ required: true, message: "Please input your password!" }]}
                        >
                            <Input.Password onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" prefix={<LockOutlined />} />
                        </Form.Item>
                        <Form.Item className='login-form-btn'>
                            <Button block type="primary" htmlType="submit" className="secure-button">
                                <img width={17} src={security} title='security' className="secure-icon" />
                                Secure Login
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
            <Footer className='login-footer' style={{ textAlign: 'center', padding: '10px 0' }}>
                © Copyright 2016 - 2024 | Michelle Seiler Tucker | Seiler Tucker Inc. | Exit Rich | All Rights Reserved.
            </Footer>
        </div>
    )
}
export default Login;